@tailwind base;
@tailwind components;
@tailwind utilities;

@import "ngx-toastr/toastr";

@font-face {
  font-family: "Belanosima";
  src: url("./assets/fonts/Belanosima/Belanosima-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Belanosima";
  // @apply text-primary;
}

h1 {
  font-size: 4em;
  line-height: 1.2em;
}
h2 {
  font-size: 2em;
  padding-bottom: 0.25em;
}
h3 {
  font-size: 1.5em;
}
h4 {
  font-size: 1em;
}
h5 {
  font-size: 0.8em;
}
h6 {
  font-size: 0.7em;
}

b {
  font-family: "Belanosima";
}

/***** CARD *****/
.card {
  // @apply bg-white rounded-box;
  @apply rounded-box;
  @apply border-2 border-solid border-gray-200;
  // @apply shadow-lg;

  .card-title {
    font-family: "Belanosima";
  }
  // .card-body{
  //   @apply p-4;
  // }

  &.card-primary {
    @apply bg-primary text-white border-primary;
  }

  &.card-secondary {
    @apply bg-secondary text-white border-secondary;
  }

  &.card-accent {
    @apply bg-accent text-white border-accent;
  }

  &.card-error {
    @apply bg-error text-white border-error;
  }
  &.card-success {
    @apply bg-success text-white border-success;
  }
  &.card-warning {
    @apply bg-warning text-white border-warning;
  }
  &.card-purple-700 {
    @apply bg-purple-700 text-white border-purple-700;
  }
  &.card-gray-700 {
    @apply bg-gray-700 text-white border-gray-700;
  }

  &.card-outline-primary {
    @apply bg-white border-primary;
  }

  &.card-outline-secondary {
    @apply bg-white border-secondary;
  }

  &.card-outline-accent {
    @apply bg-white border-accent;
  }

  &.card-home {
    // @apply bg-light-yellow border-light-yellow;

    // .mask-image-container:before {
    //   @apply border-light-yellow;
    // }
    @apply bg-primary border-primary text-white;

    .mask-image-container:before {
      @apply border-primary;
    }
  }

  &.card-button {
    @apply hover:scale-105 transition-all cursor-pointer;
  }
}

/***** COLLAPSE *****/
.collapse {
  @apply bg-white rounded-box;
  @apply border-2 border-solid border-gray-200;
}

/***** INPUT *****/
.input {
  @apply input-bordered;
}

/***** STATS *****/
.stats {
  @apply rounded-box;
  @apply border-2 border-solid border-gray-200;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 3em;
    line-height: 1em;
  }
}

.pac-container {
  @apply p-4 rounded-box shadow-none border-2 border-solid border-gray-200 z-[1000];

  .pac-item:first-child {
    @apply border-t-0;
  }
}

.dropdown-hidden {
  @apply hidden;
}

.font-4p {
  font-size: 4px;
}
.font-6p {
  font-size: 6px;
}

.border-1 {
  border-width: 1px;
}

.outline-solid {
  outline-style: solid;
}

.outline-64 {
  outline-width: 64px;
}

.w-112 {
  width: 28rem;
}

fa-icon svg {
  display: inline-block;
  font-size: inherit;
  height: 32px;
}
